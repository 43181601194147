<template>
  <main class="container">
    <div class="header h3 mt-2">
          Tarjeta de Responsabilidad
        <!-- <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Crear Nuevo</button> -->
    </div>

    <!-- add service modal -->
    <b-modal id="modal-service" title="Agregar Servicio" class="p-3" centered v-model="showNewModalServices" hide-footer>
        <form @submit.prevent="addService()" class="mx-3">
            <label class="text-green-2">Servicio:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newService.service">
            
            <label class="text-green-2">Precio:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newService.price">

            <button class="btn-1 float-end mt-4" type="submit">Aceptar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2 mt-4" type="button" @click="showNewModalServices=false">Cancelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- add product modal -->
    <b-modal id="modal-product" title="Agregar Producto" class="p-3" centered v-model="showNewModalProduct" hide-footer>
        <form @submit.prevent="addProduct()" class="mx-3">
            <label class="text-green-2">Código:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newProduct">

            <button class="btn-1 float-end mt-4" type="submit">Aceptar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2 mt-4" type="button" @click="showNewModalProduct=false">Cancelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <!-- <div class="row mt-2 mx-1" style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px"> -->
    <div class="row mt-2 " style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px">
        <div class="row">
            <div class="col-md-6">
                <label class="text-white-2 mt-2">Almacén de salida: *</label>
                <select :disabled="items.length>0" class="form-control select text-white" required v-model="newItem.warehouseId">
                    <option :value="undefined" disabled>* seleccione</option>
                    <option :value="warehouse.id" v-for="warehouse in warehouses" :key="warehouse.id">{{warehouse.name}}</option>
                </select>            
            </div>
            <div class="col-md-6">
                <label class="text-white-2 mt-2">Nombre del responsable: *</label>
                <input :disabled="items.length>0" v-model="newItem.receiverUserName" class="form-control text-white" type="text" name="" id="">
                <!-- <select :disabled="items.length>0" class="form-control select text-white" required v-model="newItem.responsabilityId" >
                    <option :value="undefined" disabled>* seleccione</option>
                    <option :value="user.id" v-for="user in users" :key="user.id">{{user.username}}</option>
                </select>             -->
            </div>
            <div class="col-md-6">
                <label class="text-white-2 mt-2">Número de identificación tributaria: *</label>
                <input :disabled="items.length>0" v-model="newItem.nit" class="form-control text-white" type="text" name="" id="">
            </div>
            <div class="col-md-6">
                <label class="text-white-2 mt-2">Sub-Estación: *</label>
                <input :disabled="items.length>0" v-model="newItem.receiverStationName" class="form-control text-white" type="text" name="" id="">
            </div>
            <div class="col-md-6">
                <label class="text-white-2 mt-2">Puesto que desempeña: *</label>
                <select :disabled="items.length>0" class="form-control select text-white" required v-model="newItem.employeeRole" >
                    <option :value="undefined" disabled>* seleccione</option>
                    <option :value="job.name" v-for="job in jobs" :key="job.id">{{job.name}}</option>
                </select>            
            </div>
            <div class="col-md-6">
                <label class="text-white-2 mt-2">Fecha de cargo: *</label>
                <input :disabled="items.length>0" v-model="newItem.creationDate" class="form-control text-white" type="date" name="" id="">
            </div>
            <div class="col-md-3">
                <button class="btn-2 float-end mt-5" @click="openNewModalServices"><i class="fas fa-plus me-2"/>Agregar Servicio</button>
            </div>
            <div class="col-md-3">
                <button class="btn-2 float-end mt-5" @click="openNewModalProduct"><i class="fas fa-plus me-2"/>Agregar bien</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8"></div>
            <!-- <div class="col-md-4">
                <button class="btn-2 float-left mt-3" @click="showNewModal=true"><i class="fas fa-plus me-2"/>Agregar Bien</button>
            </div> -->
        </div>

        <div class="row" style="top:10px">
            <b-table 
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(acciones)="data">
                    <div>                        
                        <button @click="deleteItem(data.item)" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-trash"></i></button>
                    </div>
                </template>
                 <template #cell(price)="data">
                    <span class="text-white">{{'Q ' + Number(data.item.price).toFixed(2)  }}</span>
                </template>

            </b-table> 

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn-2 float-end mt-3" @click="saveData">Crear Tarjeta de resp.&nbsp;&nbsp;<i class="fas fa-check me-2"/></button>
            </div>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},

data(){
    return {
        items:[],
        fields: [
          { key: "code", label:"Código del bien"},
          { key: "name", label:"Descripción"},
          { key: "observation", label:"Observaciones"},
          { key: "price", label:"Valor del bien"},
          { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 10,
        currentPage: 1,

        newItem:{},
        showNewModal:false,
        selectedItem:{},
        showEditModal:false,
        table: 'transfers',
        warehouses:[],
        newProduct: 'P1C000001S003',
        user: null,

        users: [],
        jobs: [
            {id: 1, name:'Administrador'},
            {id: 2, name:'Bodeguero'}
            ],
        showNewModalServices:false,
        showNewModalProduct: false,
        newService:{}


    }
},
async mounted(){
    //aqui se muestran productos no fungibles - no desechables
    await this.getWarehouses()
    await this.getUsers()
    this.user = JSON.parse(localStorage.getItem('user'))
},
methods:{
    validateData(){
        console.log('new item',this.newItem.warehouseId)
        if(this.newItem.warehouseId == undefined || this.newItem.warehouseId== ''){
            alert('Falta almacen de salida.')
            return false
        }
        if(this.newItem.receiverUserName == undefined || this.newItem.receiverUserName== ''){
            alert('Falta responsable.')
            return false
        }
        if(this.newItem.receiverStationName == undefined || this.newItem.receiverStationName== ''){
            alert('Falta Sub Estación.')
            return false
        }
        if(this.newItem.employeeRole == undefined || this.newItem.employeeRole== 0){
            alert('Falta puesto que desempeña.')
            return false
        }
        if(this.newItem.creationDate == undefined || this.newItem.creationDate== 0){
            alert('Falta fecha de cargo.')
            return false
        }
        if(this.newItem.nit == undefined || this.newItem.nit== ''){
            alert('Falta Nit.')
            return false
        }
        return true
    },
    openNewModalServices(){
        if(this.validateData()){
            this.showNewModalServices = true
        }
    },
    openNewModalProduct(){
        if(this.validateData()){
            this.showNewModalProduct = true
        }
    },
    async saveData(){
        try {
            this.newItem.senderUserId = this.user.id
            this.newItem.items = this.items
            this.newItem.status = 2
            console.log(this.newItem)
            let res = await this.$store.dispatch('post', {path: this.table + '/new/responsability' , data: this.newItem});
            console.log('res-----------------', res)
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.showNewModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                this.$router.push('detailsResponsability/'+res.insertId)
            }        
        } catch (error) {
            console.error('errror', error)
            this.$swal({title:"Aviso", text:"Error: " + error , icon:"error"})
        }
    },
    async getPreviousCode(code){
        let previousCode
        let rvData = code.split('S')
        let rvData2 = rvData[0].split('C')
        let rvInt = parseInt(rvData2[1])
        // console.log('rv --> ', rvData)
        // console.log('rv --> ', rvData2)
        // console.log('rv --> ', rvInt)
        //generando codigo anterior...
        try {
            previousCode = this.padDigits(rvInt-1, 6)
            previousCode = rvData2[0]+'C'+previousCode+'S'+rvData[1]
            // console.log(previousCode)
        } catch (error) {
            console.error(error)
            return ''
        }
        let finalData = rvData[0].substr(0,(rvData[0].length-rvInt.toString().length))
        console.log(finalData)
        // return finalData
        return previousCode
    },
    padDigits(number, digits) {
        return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },
    async addService(){

        let service={
            code: 'SERVICIO',
            productStockId: 0,
            name: this.newService.service,
            price: this.newService.price
        }
        this.items.push(service)
        this.newService = {}
        this.showNewModalServices=false

  
    },
    async addProduct(){
        try {
            //paso1 verificar si el codigo existe en productStock
            let resProductStock = await this.$store.dispatch('get', {path: 'productStock/getByCodeAndWarehouseId/' + this.newProduct+'/'+this.newItem.warehouseId});
            if(resProductStock.length>0){
                if(resProductStock[0].transferId ==0){
                    console.log('continue...')
                }
                else{
                    alert('producto ya fue transferido')
                    return
                }
            }
            else{
                alert('no existe producto ó no pertenece al almacen seleccionado.')
                return
            }
            let previousCode = await this.getPreviousCode(this.newProduct)
            let newData= {}

            let res = await this.$store.dispatch('get', {path: 'transfers/productVerifyByWarehouse/' + this.newProduct+ '/' + this.newItem.warehouseId});
            if(res.message){
                if(res.message != 'ok'){
                    //si es diferente a ok es porque no lleva continuidad pero es probable 
                    //que tenga secuencia con un producto que está agregado a la lista local,
                    // entonces verificar si el prod. anterior está en lista local
                    
                    //buscando codigo anterior en items
                    // console.log('evaluando si está en local')
                    // console.log(this.items)
                    let searchingPreviousCode = this.items.filter((x) => x.code === previousCode)
                    // console.log('searchingPreviousCode',searchingPreviousCode)
                    if (searchingPreviousCode.length>0){
                        // console.log('ya existe en lista, pero se puede agregar...')
                        newData = {
                            code: resProductStock[0].code,
                            productStockId: resProductStock[0].id,
                            name: resProductStock[0].name,
                            price: resProductStock[0].price
                        }
                        //console.log(newData)
                        this.items.push(newData)
                        this.showNewModalProduct=false
                        return;
                    }
                    else{
                        alert('Error, el codigo debe ser:' + previousCode )
                        return
                    }
                    alert(res.message)
                    return;
                }
                //paso2: verificar si el producto no se ha agregado a variable local
                let it = this.items.filter((x) => x.code === this.newProduct)
                if (it.length>0){
                    alert('ya existe en lista...')
                    return;
                }
                newData = {
                    code: res.data[0].code,
                    productStockId: res.data[0].id,
                    name: res.data[0].name,
                    price: res.data[0].price
                }
                this.items.push(newData)
                this.showNewModalProduct=false
            }
        } catch (error) {
            this.warehouses = []
            console.error('error', error)
        }
    },
    async getUsers(){
        try {
            let res = await this.$store.dispatch('get', {path: 'users/getAll'});
            //console.log(res)
            if (res.length >0){
                this.users = res
            }
        } catch (error) {
            this.users = []
            console.error('error', error)
        }
    },
    async getWarehouses(){
        try {
            let res = await this.$store.dispatch('get', {path: 'warehouses/getAll'});
            //console.log(res)
            if (res.length >0){
                this.warehouses = res
            }
        } catch (error) {
            this.warehouses = []
            console.error('error', error)
        }
    },
    async deleteItem(item){
        const {isConfirmed} = await this.$swal({
            title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
            icon:"question",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            reverseButtons: true

        })
        if(isConfirmed){
            let t = this.items.indexOf(item)
            console.log(t)
            this.items.splice(t,1)
        }
    }
}
}
</script>

<style>
.form-control:disabled, .form-control[readonly]{
    color:rgb(53, 52, 52) !important;
    
}
</style>